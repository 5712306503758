/* ==========================================================================
   #ALIGNMENT
   ========================================================================== */
/**
 * Utility classes to put specific text-align values onto elements.
 */

$inuit-alignment-responsive: false !default;

$inuit-responsive-alignment-until-include: default !default;
$inuit-responsive-alignment-until-exclude: default !default;
$inuit-responsive-alignment-from-include: default !default;
$inuit-responsive-alignment-from-exclude: default !default;

.u-align-left { text-align: left !important; }
.u-align-right { text-align: right !important; }
.u-align-center { text-align: center !important; }
.u-align-justify { text-align: justify !important; }


@if $inuit-alignment-responsive {

  /* Responsive alignment helper classes */
  @include responsify(
    $until-include: $inuit-responsive-alignment-until-include,
    $until-exclude: $inuit-responsive-alignment-until-exclude,
    $from-include: $inuit-responsive-alignment-from-include,
    $from-exclude: $inuit-responsive-alignment-from-exclude
    ) {

    .u-align-left#{$breakpoint} { text-align: left !important; }
    .u-align-right#{$breakpoint} { text-align: right !important; }
    .u-align-center#{$breakpoint} { text-align: center !important; }
    .u-align-justify#{$breakpoint} { text-align: justify !important; }

  }

}

