/*------------------------------------*\
    #LINKS
\*------------------------------------*/

/* Style any element as link */
.u-link {
  color: $color-link !important;
  border-bottom: 1px solid $color-link !important;

  &:hover,
  &:focus,
  a:hover &,
  a:focus & {
    color: $color-link-hover !important;
    border-bottom-color: $color-link-hover;
  }
}



/* Hide a link */
.u-link--stealth {
  text-decoration: inherit;
  color: inherit !important;
  border-bottom-style: initial;

  &:hover,
  &:focus,
  a:hover &,
  a:focus & {
    color: $color-link-hover !important;
    border-bottom-style: initial;
  }
}
