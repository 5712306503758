/* ==========================================================================
  #LINKS
  ========================================================================== */

a {
  color: $color-link;
  text-decoration: none;
  border-bottom: 1px solid $color-link;

  &:hover,
  &:active {
    color: $color-link-hover;
    border-bottom-color: $color-link-hover;
  }
}
