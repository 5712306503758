///* ========================================================================
//   #GLOBAL
//   ======================================================================== */
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.


$inuit-global-spacing-unit: 1.5rem;

$nav-height: 4.5rem !default;

// Standardise some UI treatments.
$global-radius: 3px !default;
$global-transition: all 100ms ease-out !default;

$icon-sizes: (
  auto: 1em, // required
  xxxs: $fs-xxxs,
  xxs: $fs-xxs,
  xs: $fs-xs,
  s: $fs-s,
  base: $fs-base, // required
  l: $fs-l,
  xl: $fs-xl,
  xxl: $fs-xxl,
  xxxl: $fs-xxxl,
) !default;
