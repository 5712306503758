/* ==========================================================================
   #FIGURE
   ========================================================================== */

.c-figure {
}

.c-figure__footer {
  float: right;
  padding-right: $unit-tiny;
  line-height: 1;
}

.c-figure__copyright {
  font-size: $fs-xxs;
  opacity: .7;
}

.c-figure__caption {
  margin-top: $unit-tiny;
  font-size: $fs-s;
  opacity: .7;
}

.c-figure--effect {

  .c-lazy-container {
    background: rgba($color-brand, .4);
    box-shadow: 0 0 0 1px rgba($color-brand, .5);

    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: $color-background-dark;
      mix-blend-mode: color-dodge;
    }

    > img {
      mix-blend-mode: hard-light;
      filter: grayscale(1);
    }
  }
}
