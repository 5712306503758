/* ==========================================================================
   #CONTENT-BLOCK
   ========================================================================== */


.c-content-block {
  //box-sizing: content-box;
  //max-width: $text-width;
  //margin-right: auto;
  //margin-left: auto;
  //padding-right: $unit;
  //padding-left: $unit;


  + .c-content-block {
    margin-top: $unit;
  }
}
