/* ==========================================================================
   #HERO
   ========================================================================== */


.c-hero {
  //margin-bottom: -$unit-huge;
  position: relative;

  @include mq($from: desk) {
    min-height: $unit-huge * 3;
    margin-top: -$nav-height;
    background-color: $color-background-medium;
  }
}

.c-hero__wrapper {
  max-width: $hero-width;
  margin: 0 auto;
}


.c-hero__image {
  object-fit: cover;
  width: 100%;
  height: 100%;

  @include mq($from: desk) {
    //margin-top: -$nav-height;
  }
}


.c-hero__image-caption {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: $unit-tiny / 2 $unit-tiny;
  font-size: $fs-xxs;
  background-color: rgba($color-background-dark, .7);
}


.c-hero__image-wrapper {
  max-height: 80vh;
  overflow: hidden;

  @include mq($until: desk) {
    margin-bottom: -2rem;
  }
}

.c-hero__title {
  position: relative;
  display: inline-block;
  margin: 0;
  margin-left: -$unit;
  padding: $unit-small $unit;
  background-color: rgba($color-background-dark, .7);

  @include mq($from: desk) {
    margin-left: 0;
  }
}

.c-hero__content {


  @include mq($from: desk) {
    position: absolute;
    bottom: $unit;
    left: 0;
    right: 0;
    padding: $unit;

  }
}


.c-hero__content-wrapper {
  outline: 2px solid red;
  max-width: $inuit-wrapper-width / 2;
}
