/* ==========================================================================
   INUITCSS
   ========================================================================== */

/**
 * inuitcss, by @csswizardry
 *
 * github.com/inuitcss | inuitcss.com
 */

/**
 * This is an example inuitcss manifest file. DO NOT MODIFY THIS FILE DIRECTLY.
 * Instead, copy and paste it into your own CSS directory and make additions,
 * extensions, and modifications there.
 */

/**
 * CONTENTS
 *
 * SETTINGS
 * Config...............Project-level configuration and feature switches.
 * Core.................inuitcss’ core and setup settings.
 * Gl./pu obal...............Project-wide variables and settings.
 *
 * TOOLS
 * Font-size............A mixin which guarantees baseline-friendly line-heights.
 * Clearfix.............Micro clearfix mixin.
 * Hidden...............Mixin for hiding elements.
 * Sass MQ..............inuitcss’ default media query manager.
 *
 * GENERIC
 * Box-sizing...........Better default `box-sizing`.
 * Normalize.css........A level playing field using @necolas’ Normalize.css.
 * Reset................A tiny reset to complement Normalize.css.
 * Shared...............Sensibly and tersely share some global commonalities
 *                      (particularly useful when managing vertical rhythm).
 *
 * ELEMENTS
 * Page.................Set up our document’s default `font-size` and
 *                      `line-height`.
 * Headings.............Very minimal (i.e. only font-size information) for
 *                      headings 1 through 6.
 * Images...............Base image styles.
 * Tables...............Simple table styles.
 *
 * OBJECTS
 * Wrapper..............Page constraint object.
 * Layout...............Generic layout module.
 * Media................Image- and text-like content side by side. The
 *                      poster-child of OOCSS.
 * Flag.................Table-layout-based advancement on the Media object.
 * List-bare............Lists with no bullets or indents.
 * List-inline..........A list whose items all site in a line.
 * Box..................Simple boxing abstraction.
 * Block................Image-on-top-of-text object.
 * Ratio................A container for maintaining aspect ratio of content.
 * Crop.................Provide a cropping context for media (images, etc.).
 * Table................Classes for manipulating `table`s.
 * Pack.................Pack items into available horizontal space.
 *
 * COMPONENTS
 * Buttons..............An example button component, and how it fits into the
 *                      inuitcss framework.
 *
 * UTILITIES
 * Clearfix.............Bind our clearfix onto a utility class.
 * Widths...............Simple width helper classes.
 * Headings.............Reassigning our heading styles to helper classes.
 * Spacings.............Nudge bits of the DOM around with these spacing
 *                      classes.
 * Responsive-Spacings..Enhances the function of normal spacings for
 *                      responsive usage.
 * Print................Reset-like styles taken from the HTML5 Boilerplate.
 * Hide.................Helper classes to hide content
 */

@import '../../node_modules/modularscale-sass/stylesheets/modularscale';

// SETTINGS
// @import "../../node_modules/inuitcss/settings/example.settings.config";
// @import "../../node_modules/inuitcss/settings/settings.core";
// @import "../../node_modules/inuitcss/settings/example.settings.global";
@import "settings/settings.config";
@import "settings/settings.typography";
@import "settings/settings.breakpoints";
@import "settings/settings.global";
@import "settings/_inuit.settings.core";
@import "settings/settings.colors";





// TOOLS
// @import "../../node_modules/inuitcss/tools/tools.font-size";
@import "tools/tools.font-family";
@import "tools/tools.font-face";
@import "tools/tools.font-size";
@import "tools/tools.fluid";
@import "tools/tools.hide-visually";
@import "tools/tools.responsify";
@import "../../node_modules/inuitcss/tools/tools.clearfix";
@import "../../node_modules/inuitcss/tools/tools.hidden";
@import "../../node_modules/sass-mq/mq";
@import "tools/tools.aliases";





// GENERIC
// @import "../../node_modules/inuitcss/generic/generic.box-sizing";
@import "generic/generic.box-sizing";
@import "../../node_modules/inuitcss/generic/generic.normalize";
@import "../../node_modules/inuitcss/generic/generic.reset";
// @import "../../node_modules/inuitcss/generic/generic.shared";
@import "generic/generic.shared";
@import "generic/generic.reset";
@import "generic/generic.i18n";





// ELEMENTS
// @import "../../node_modules/inuitcss/elements/elements.page";
@import "elements/elements.fonts";
@import "elements/elements.page";
@import "../../node_modules/inuitcss/elements/elements.headings";
@import "../../node_modules/inuitcss/elements/elements.images";
@import "../../node_modules/inuitcss/elements/elements.tables";
@import "elements/elements.headings";
@import "elements/elements.inline";
@import "elements/elements.links";
@import "elements/elements.paragraphs";
@import "elements/elements.buttons";





// OBJECTS
//
// Uncomment and add to this section as necessary.
@import "../../node_modules/inuitcss/objects/objects.wrapper";
@import "../../node_modules/inuitcss/objects/objects.layout";
@import "objects/objects.layout-additions";

$inuit-layout-bottom-gutter: true;
@import "objects/objects.layout-bottom-gutter";
@import "../../node_modules/inuitcss/objects/objects.media";
@import "../../node_modules/inuitcss/objects/objects.flag";
@import "../../node_modules/inuitcss/objects/objects.list-bare";
@import "../../node_modules/inuitcss/objects/objects.list-inline";
@import "../../node_modules/inuitcss/objects/objects.box";
@import "../../node_modules/inuitcss/objects/objects.block";

$inuit-ratios: (
  "2\\:1"   : (2:1),
  "4\\:3"   : (4:3),
  "16\\:9"  : (16:9)
) !default;
@import "../../node_modules/inuitcss/objects/objects.ratio";
@import "../../node_modules/inuitcss/objects/objects.crop";
@import "../../node_modules/inuitcss/objects/objects.table";
@import "../../node_modules/inuitcss/objects/objects.pack";
@import "objects/objects.icons";
@import "objects/objects.page-wrapper";
@import "objects/objects.toggle";




// COMPONENTS
//
// Build and include your project’s UI Compoments here.
// @import "../../node_modules/inuitcss/components/example.components.buttons";
@import "components/_components.buttons";
@import "components/_components.blockquote";
@import "components/_components.content-block";
@import "components/_components.content-section";
@import "components/_components.figure";
@import "components/_components.headings";
@import "components/_components.hero";
@import "components/_components.lazyload";
@import "components/_components.nav";
@import "components/_components.nav-foot";
@import "components/_components.page-body";
@import "components/_components.page-branding";
@import "components/_components.page-foot";
@import "components/_components.page-head";





// SCOPES
@import "scopes/scopes.dark";
@import "scopes/scopes.text";





// UTILITIES
@import "../../node_modules/inuitcss/utilities/utilities.clearfix";

$inuit-fractions: 1 2 3 4 5 8 !default;
$inuit-offsets: true;
@import "../../node_modules/inuitcss/utilities/utilities.widths";
@import "../../node_modules/inuitcss/utilities/utilities.headings";
@import "../../node_modules/inuitcss/utilities/utilities.spacings";


$inuit-responsive-spacing-directions: (
  null: null,
  "-bottom": "-bottom",
  "-horizontal": "-left" "-right",
);

$inuit-responsive-spacing-properties: (
  "margin": "margin",
);

$inuit-responsive-spacing-sizes: (
  "-small": $unit-small,
  "-none": 0
);
@import "../../node_modules/inuitcss/utilities/utilities.responsive-spacings";


@import "../../node_modules/inuitcss/utilities/utilities.print";
@import "../../node_modules/inuitcss/utilities/utilities.hide";

$inuit-alignment-responsive: true;
@import "utilities/utilities.alignment";
@import "utilities/utilities.center";

$inuit-colors: true;
$inuit-background-colors: true;
@import "utilities/utilities.colors";

$inuit-responsive-display-block: true;
@import "utilities/utilities.display";

$inuit-responsive-text: true;
@import "utilities/utilities.text";
@import "utilities/utilities.text-width";

@import "utilities/utilities.ratio";
@import "utilities/utilities.lob-owl";
@import "utilities/utilities.image-effect";
@import "utilities/utilities.links";
@import "utilities/utilities.hide-expanded";
@import "utilities/utilities.hide-js";

$inuit-responsive-width-auto: true;
@import "utilities/utilities.width-auto";



@import "shame";

