/* ==========================================================================
   #PAGE-HEAD
   ========================================================================== */


.c-page-head {
  margin-right: -$unit;
  margin-left: -$unit;

  @include mq($from: desk) {
    position: relative;
    z-index: 1;
  }
}
