/* ==========================================================================
   #NAV
   ========================================================================== */


.c-nav-primary-toggle {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  padding: $unit-small;
  font-size: $fs-s;
  text-decoration: none;
  border: none;
  color: $color-text-invert;
  background-color: rgba($color-background-dark, 1);

  &:hover,
  &:focus,
  &:active {
    //color: $color-text;
    //background: $color-background-white;
  }

  &[aria-expanded="true"] {
    color: $color-text;
    background: none;

    .s-slug-home & {
      color: $color-text-invert;
    }
  }

  > svg {
    display: block;
    font-size: 16px;
  }

  @include mq($from: lap) {
    display: none;
  }
}

.c-nav-primary-toggle__label {
  margin-right: $unit-tiny;
  line-height: 0;

  .is-active > & {
    display: none;
  }
}

.c-nav-primary {
  //display: none;
  position: relative;
  z-index: 1;
  list-style-type: none;
  margin: 0;

  @include mq($until: lap) {
    background: none;
    background-color: $color-background-light;
    box-shadow: 0 .3rem 3rem rgba($color-text,.3);

    .c-nav-primary__item {
      display: block;
    }
  }

  @include mq($from: lap) {
    display: flex;
    background-color: rgba($color-background-light, 1);
  }

  @include mq($from: desk) {
    background-color: rgba($color-background-light, .8);
  }

}

.c-nav-primary__item {
  display: flex;

  + .c-nav-primary__item {
    // margin-left: 1em;
  }
  @include mq($from: desk) {
    flex: 1 1 auto;
  }
}


.c-nav-primary__link {

  display: flex;
  align-items: center;
  color: $color-text;
  border-bottom: none;
  padding: $unit-small;
  font-size: $fs-s;
  transition: $global-transition;

  @include mq($from: lap) {
    padding: $unit-small;
  }

  @include mq($from: desk) {
    width: 100%;
    height: $nav-height;
    padding: $unit;
    justify-content: center;
    white-space: nowrap;
    font-size: $fs-base;
  }

  &:hover,
  &:focus,
  &:active {
    border: none;
    color: $color-text;
    background: $color-background-white;
  }

  .is-current & {
    box-shadow: 0 2px 0 $color-brand;
    background: $color-background-dark;
    color: $color-brand;

    @include mq($from: lap) {
      box-shadow: 0 $unit-tiny 0 $color-brand;
    }
  }

  .c-nav-primary__item-home & {
    font-weight: $fw-bold;
  }
}

.c-nav-primary__item-home {
  white-space: nowrap;

  @include mq($from: lap) {
    background-color: $color-background-white;
  }
}
