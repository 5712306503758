/* ==========================================================================
   #NAV-FOOTER
   ========================================================================== */


.c-nav-foot {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  margin: 0 auto;
}

.c-nav-foot__item {
  margin: 0 $unit-small;
}
