/* ==========================================================================
   #TEXT SCOPE
   ========================================================================== */

.s-text {

  > * {
    margin-bottom: 0;

    + * {
      margin-top: $unit-small;
    }
  }

  > :first-child {
    margin-top: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    line-height: 1.1;
    font-weight: $fw-bold;
  }

  h1 {
    font-size: $fs-h1;
  }

  h2 {
    margin-top: $unit-large;
    padding-bottom: $unit;
    font-size: $fs-h2;
  }

  h3 {
    margin-top: $unit;
    font-size: $fs-h3;
  }

  h4 {
    margin-top: $unit;
    font-size: $fs-h4;
  }

  li {

    + li {
      margin-top: $unit-tiny;
    }
  }

  ul,
  ol {
    overflow: hidden;
    margin-left: 0;
    padding-left: $unit;
  }

  ul {
    list-style: none;

    li {
      position: relative;

      &::before {
        content: "-";
        font-style: italic;
        position: absolute;
        right: 100%;
        top: 0;
        margin-right: $unit-small;
      }
    }
  }
}
