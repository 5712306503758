/* ==========================================================================
   #HEADINGS
   ========================================================================== */



.c-heading-alpha,
.c-heading-beta,
.c-heading-gamma,
.c-heading-delta,
.c-heading-epsilon,
.c-heading-zeta {
  line-height: 1.3;
  font-weight: $fw-medium;

  a {
    border-style: none;
  }
}

.c-heading-alpha {
  font-size: $fs-h1;
}

.c-heading-beta {
  font-size: $fs-h2;
  color: $color-brand;

  @include mq($from: desk) {
    margin-top: -.3rem;
  }
}

.c-heading-gamma {
  font-size: $fs-h3;
}

.c-heading-delta {
  font-size: $fs-h4;
}

.c-heading-epsilon {
  font-size: $fs-h5;
}

.c-heading-zeta {
  font-size: $fs-h6;
}


.c-heading-hero {
  font-size: $fs-xxxl;
  font-weight: $fw-medium;
  line-height: 1.1;
  color: $color-text-invert;

  @include mq($from: lap) {
    font-size: $fs-xxxxl;
  }
  //max-width: 40rem;
}

.c-heading-page {
    //max-width: 40rem;
}
