/* ==========================================================================
   #CENTERING
   ========================================================================== */

$inuit-centering: true !default;
$inuit-responsive-centering: false !default;

$inuit-responsive-centering-until-include: default !default;
$inuit-responsive-centering-until-exclude: default !default;
$inuit-responsive-centering-from-include: default !default;
$inuit-responsive-centering-from-exclude: default !default;


@if $inuit-centering {

  /* Centering helper class */
  .u-center {
    margin-right: auto !important;
    margin-left: auto !important;
  }

}



@if $inuit-responsive-centering {

  /* Responsive centering helper classes */
  @include responsify(
    $until-include: $inuit-responsive-centering-until-include,
    $until-exclude: $inuit-responsive-centering-until-exclude,
    $from-include: $inuit-responsive-centering-from-include,
    $from-exclude: $inuit-responsive-centering-from-exclude
    ) {

    .u-center#{$breakpoint} {
      margin-right: auto !important;
      margin-left: auto !important;
    }
  }

}
