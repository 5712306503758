/* ==========================================================================
   #TOGGLE
   ========================================================================== */

.o-toggle {

  &:not([aria-controls]) {
    display: none;
  }


}

.o-toggle-target {
  display: none;

  &[aria-hidden='false'] {
    display: initial;
  }


}
