/* ==========================================================================
   #LOBOTOMIZED OWL
   ========================================================================== */

.u-lob-owl {

  > * + * {
    margin-top: $unit;
  }
}


.u-lob-owl-large {

  > * + * {
    margin-top: $unit-large;
  }
}


.u-lob-owl-huge {

  > * + * {
    margin-top: $unit-huge;
  }
}
