/* ==========================================================================
   #CONTENT-SECTION
   ========================================================================== */


.c-content-section {
  padding-top: $unit;
  padding-bottom: $unit;

  @include mq($from: desk) {
    padding-top: $unit-large;
  }
}
