/* ==========================================================================
  #RESET
  ========================================================================== */

/**
 * Reset button styles.
 */
button {
  background: none;
  border: none;
  padding: 0;
  line-height: normal;
}
