/* ==========================================================================
   #HIDE-JS
   ========================================================================== */

$inuit-hide-js: true !default;
$inuit-responsive-hide-js: false !default;

$inuit-responsive-hide-js-until-include: default !default
$inuit-responsive-hide-js-until-exclude: default !default
$inuit-responsive-hide-js-from-include: default !default
$inuit-responsive-hide-js-from-exclude: default !default

@if $inuit-hide-js {

  /* Hide helper class */
  .js .u-hide-js {
    display: none !important;
  }

  /* Hide no-js helper class */
  .no-js .u-hide-no-js {
    display: none !important;
  }

}



@if $inuit-responsive-hide-js {

  /* Responsive hide js/no-js helper classes */
  @include responsify(
    $until-include: $inuit-responsive-hide-js-until-include,
    $until-exclude: $inuit-responsive-hide-js-until-exclude,
    $from-include: $inuit-responsive-hide-js-from-include,
    $from-exclude: $inuit-responsive-hide-js-from-exclude
    ) {

    .js .u-hide-js#{$breakpoint} {
      display: none !important;
    }
    .no-js .u-hide-no-js#{$breakpoint} {
      display: none !important;
    }

  }

}
