///* ========================================================================
//   #FLUID VALUES
//   ======================================================================== */


// Default breakpoints
$fluid-from-bp: 30em !default;
$fluid-to-bp: 80em !default;


/// Remove the unit of a length
///
/// @link https://css-tricks.com/snippets/sass/strip-unit-function/
///
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
///
@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}



///
/// Output a CSS calc function that contrains a value from A to B over
/// A-viewport-width to B-viewport-width. Require a media query to cap
/// the value at B.
///
/// Updated by Dennis Frank to
///
///
/// @usage
/// .article {
///   font-size: 12px;
///   @media screen and ( min-width: 640px ) {
///     font-size: between( 12px, 16px, 640px, 1280px );
///   }
///   @media screen and ( min-width: 1280px ) {
///     font-size: 16px;
///   }
/// }
///
/// @link https://css-tricks.com/between-the-lines/
/// @demo https://codepen.io/dbj/pen/XMGgjz
///
/// @param {Number} $from      Minimum size in pixels
/// @param {Number} $to        Maximum size in pixels
/// @param {Number} $fromWidth Minimum viewport width in pixels
/// @param {Number} $toWidth   Maximum viewport width in pixels
///
@function between( $fromValue, $toValue, $fromWidth, $toWidth ) {

  @return calc(#{$fromValue} + #{strip-unit($toValue - $fromValue)} * (100vw - #{$fromWidth}) / (#{strip-unit($toWidth)} - #{strip-unit($fromWidth)}));

}



///
/// Constrain a property between two values, between two breakpoints.
///
/// @usage
/// .widget {
///   @include fluid-value( "padding", 1em 2em, 1.5em 3em );
/// }
///
/// In this case, padding at the smallest size will be 1em 2em, and it will
/// increase proportionally with the viewport width until it reaches 1.5em 3em.
/// Works with all sized properties i.e width, max-height, font-size, etc.
///
/// Note: "auto", "0" and percentage-based values can be passed, but they will
///       not be processed. For the `$fromBp` media query, `$fromValue` will
///       be used in their place.
///
/// @uses between() function
///
/// @link https://codepen.io/LucaRosaldi/pen/RgPbeR
///
/// @param {String} $property  Property
/// @param {Number} $fromValue Minimum value(s)
/// @param {Number} $toValue   Maximum value(s)
/// @param {Number} [$fromBp]  Value(s) increase after this breakpoint
/// @param {Number} [$toBp]    Value(s) are capped at this breakpoint
///
@mixin fluid-value(
  $property,
  $fromValue,
  $toValue,
  $fromBp: $fluid-from-bp,
  $toBp: $fluid-to-bp
) {

  $fromValues: ();
  $betweenValues: ();
  $toValues: ();

  @for $i from 1 through length( $fromValue ) {
    $fv: nth( $fromValue, $i );
    $tv: nth( $toValue, $i );

    $bv: $fv;
    $bvf: false;
    $bvt: false;

    @if ( type-of( $fv ) == "number" and not unitless( $fv ) and unit( $fv ) != "%" ) {
      //$fv: if( unit( $fv ) != "px", ( $fv / ( $fv * 0 + 1 ) * 16 ) * 1px, $fv );
      $bvf: $fv;
    }
    @if ( type-of( $tv ) == "number" and not unitless( $tv ) and unit( $tv ) != "%" ) {
      // $tv: if( unit( $tv ) != "px", ( $tv / ( $tv * 0 + 1 ) * 16 ) * 1px, $tv );
      $bvt: $tv;
    }
    @if ( $bvf != false and $bvt != false ) {
      $bv: between( $bvf, $bvt, $fromBp, $toBp );
    }

    $fromValues: append( $fromValues, $fv );
    $betweenValues: append( $betweenValues, $bv );
    $toValues: append( $toValues, $tv );
  }

  #{$property}: $fromValues;
  @media screen and (min-width: #{$fromBp}) {
    #{$property}: $betweenValues;
  }
  @media screen and (min-width: #{$toBp}) {
    #{$property}: $toValues;
  }
}


