/* ==========================================================================
   #LAZYLOAD
   ========================================================================== */

$transition-lazy: 0.4s opacity ease-out !default;

/* without js hide lazyloaded elements because of noscript fallback */
.no-js {

  .c-lazy-container,
  img.lazyload {
    display: none;
  }
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: $transition-lazy;
}

.c-lazy-container {
  position: relative;
  height: 0;
  display: block;
  width: 100%;
}

.c-lazy-container > img,
.c-lazy-container > iframe,
.c-lazy-container > video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
}
