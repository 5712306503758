/* ==========================================================================
   #LAYOUT-BOTTOM-GUTTERS
   ========================================================================== */

/**
 * This extension uses an `:after` pseudo element with negative top margin
 * applied to `.layout` object to negate layout items bottom margin.
 */

// Predefine the variables below in order to alter and enable specific features.

$inuit-layout-bottom-gutter:           $inuit-global-spacing-unit !default;
$inuit-layout-bottom-gutter--tiny:     $inuit-global-spacing-unit-tiny !default;
$inuit-layout-bottom-gutter--small:    $inuit-global-spacing-unit-small !default;
$inuit-layout-bottom-gutter--large:    $inuit-global-spacing-unit-large !default;
$inuit-layout-bottom-gutter--huge:     $inuit-global-spacing-unit-huge !default;

$inuit-enable-layout--bottom-gutter-tiny:     false !default;
$inuit-enable-layout--bottom-gutter-small:    false !default;
$inuit-enable-layout--bottom-gutter-large:    false !default;
$inuit-enable-layout--bottom-gutter-huge:     false !default;
$inuit-enable-layout--bottom-gutter-flush:     false !default;





/**
 * Default layout.
 *
 * 1. Make sure `:after` pseudo element takes the whole width of its parent.
 * 2. Negate layout item's bottom margin.
 */
[class^="o-layout--bottom-gutter"],
[class*=" o-layout--bottom-gutter"] {

    &:after {
        content: "";
        display: block; /* [1] */
        margin-top: -($inuit-layout-bottom-gutter); /* [2] */
    }

    > .o-layout__item,
    > %o-layout__item {
        margin-bottom: $inuit-layout-bottom-gutter;
    }

}






@if ($inuit-enable-layout--bottom-gutter-tiny == true) {

    /**
     * Layouts with tiny gutters.
     */

    .o-layout--bottom-gutter-tiny,
    %o-layout--bottom-gutter-tiny {

        &:after {
            margin-top: -($inuit-layout-bottom-gutter--tiny);
        }

        > .o-layout__item,
        > %o-layout__item {
            margin-bottom: $inuit-layout-bottom-gutter--tiny;
        }

    }

}

@if ($inuit-enable-layout--bottom-gutter-small == true) {

    /**
     * Layouts with small gutters.
     */

    .o-layout--bottom-gutter-small,
    %o-layout--bottom-gutter-small {

        &:after {
            margin-top: -($inuit-layout-bottom-gutter--small);
        }

        > .o-layout__item,
        > %o-layout__item {
            margin-bottom: $inuit-layout-bottom-gutter--small;
        }

    }

}

@if ($inuit-enable-layout--bottom-gutter-large == true) {

    /**
     * Layouts with large gutters.
     */

    .o-layout--bottom-gutter-large,
    %o-layout--bottom-gutter-large {

        &:after {
            margin-top: -($inuit-layout-bottom-gutter--large);
        }

        > .o-layout__item,
        > %o-layout__item {
            margin-bottom: $inuit-layout-bottom-gutter--large;
        }

    }

}

@if ($inuit-enable-layout--bottom-gutter-huge == true) {

    /**
     * Layouts with huge gutters.
     */

    .o-layout--bottom-gutter-huge,
    %o-layout--bottom-gutter-huge {

        &:after {
            margin-top: -($inuit-layout-bottom-gutter--huge);
        }

        > .o-layout__item,
        > %o-layout__item {
            margin-bottom: $inuit-layout-bottom-gutter--huge;
        }

    }

}





@if ($inuit-enable-layout--bottom-gutter-flush == true) {

    /**
     * Layouts with no gutters.
     */

    .o-layout--bottom-gutter-flush,
    %o-layout--bottom-gutter-flush {

        &:after {
            margin-top: 0;
        }

        > .o-layout__item,
        > %o-layout__item {
            margin-bottom: 0;
        }

    }

}
