///* ========================================================================
//   #COLORS
//   ======================================================================== */


///* Pure Color Palette
//   ======================================================================== */

$color-black: #000 !default;
$color-white: #fff !default;

$color-orange: hsl(40, 71%, 50%) !default;
$color-yellow: #eaa210 !default;

$color-blue-dark: #0e1628 !default;
$color-blue-light: #41506f !default;

$color-gray-lightest: #dedede !default;

$color-gray: #777 !default;
$color-gray-dark: #323238 !default;
$color-gray-darker: #222324 !default;


///* Abstract Colors Palette
//   ======================================================================== */

$color-brand: $color-yellow;
$color-page: $color-blue-dark;
$color-text: $color-blue-dark;
$color-text-invert: $color-white;
$color-text-light: $color-gray;

$color-link: $color-yellow;
$color-link-hover: darken($color-yellow, 4%);
$color-link-active: darken($color-yellow, 10%);

$color-border: $color-black;
$color-background-dark: $color-blue-dark;
$color-background-medium: $color-blue-light;
$color-background-light: $color-gray-lightest;
$color-background-white: $color-white;

$color-input: $color-text;
$color-input-background: $color-white;
$color-input-border: $color-text;


///* Primary theme color (default)
//   ======================================================================== */

$color-theme-primary-rgb: 0,0,0;
$color-theme-primary: rgb(0,0,0);
:root { --theme-color-primary: $color-theme-primary-rgb; }


$colors: (
    brand:        $color-brand,
    text:         $color-text,
    text-invert:  $color-text-invert,
    text-light:   $color-text-light,
    page:         $color-page,
    light: $color-background-light
  ) !default;
