/* ==========================================================================
   #FONTS
   ========================================================================== */

@include font-faces($fonts);

/* Disable ligature */
* {
  font-variant-ligatures: no-common-ligatures;
}
