/* ==========================================================================
   #PAGE-WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

$page-wrapper-width: 1200px !default;

@if (type-of($page-wrapper-width) != number) {
  @error "`#{$page-wrapper-width}` needs to be a number.";
}

.o-page-wrapper {
  @include inuit-clearfix();
  /*
  padding-right: $inuit-global-spacing-unit;
  padding-left:  $inuit-global-spacing-unit;
  */
  margin-right: auto;
  margin-left:  auto;
  max-width: $page-wrapper-width;
}
