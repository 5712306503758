/* ==========================================================================
   #BLOCKQUOTE
   ========================================================================== */

.c-blockquote {
  text-align: center;
}

.c-blockquote__body {
  font-size: $fs-xxl;
  line-height: 1.3333;

  > :first-child::before,
  > :last-child::after {
    font-size: 1.2em;
    line-height: 0;
  }

  > :first-child::before {
    content: open-quote;
    margin-right: .1em;
    margin-left: -.1em;
  }

  > :last-child::after {
    content: close-quote;
    margin-left: .1em;
  }

  > :last-child {
    margin-bottom: 0;
  }

  &:before {
    content: open-quote;
  }
  &:after {
    content: close-quote;
  }

}

.c-blockquote__footer {
  margin-top: $unit-small;
}

.c-blockquote__cite {
  font-size: $fs-s;
  font-style: normal;
  color: $color-gray;
}
