///* ========================================================================
//   #TYPOGRAPHY
//   ======================================================================== */

// Modular Scale:
// http://www.modularscale.com/?1&rem&1.2&sass&text
$modularscale: (
  base: 1rem,
  ratio: 1.125
);


// @import "modular-scale/stylesheets/_modular-scale";
//@import 'modularscale-sass/stylesheets/modularscale';

$base-font: pw Tisa Sans !default;
// $secondary-font: imperial-urw !default;
$ff-base: "#{$base-font}", Helvetica, Arial, sans-serif !default;
// $ff-secondary: "#{$secondary-font}", Helvetica, Arial, sans-serif !default;

// Fonts
$fonts: (
  display: (
    title: "PW Tisa Sans",
    name: "pw Tisa Sans",
    path: "../fonts/",
    fallback: "'Helvetica Neue', Helvetica, Arial, sans-serif",
    formats: woff woff2,
    styles: (
      "normal": (
        file: "ff-tisa-sans-w01-regular",
        font-style: normal,
        font-weight: 400,
      ),
      // "italic": (
      //   file: "museosans_300italic_macroman/MuseoSans_300_Italic-webfont",
      //   font-style: italic,
      //   font-weight: 300
      // ),
      "medium": (
        file: "ff-tisa-sans-w01-medium",
        font-style: normal,
        font-weight: 500,
      )
      // "medium italic": (
      //   file: "museosans_500italic_macroman/MuseoSans_500_Italic-webfont",
      //   font-style: italic,
      //   font-weight: 500
      // ),
    )
  )
) !default;


// Font weights
//$fw-light: 300 !default;
$fw-normal: 400 !default;
$fw-regular: $fw-normal !default;
$fw-medium: 500 !default;
// $fw-semibold: 500 !default;
$fw-bold: $fw-medium !default;

// Font sizes
$fs-xxxs: ms(-6) !default;
$fs-xxs: ms(-3) !default;
$fs-xs: ms(-2) !default;
$fs-s: ms(-1) !default;
$fs-base: ms() !default;
$fs-l: ms(1) !default;
$fs-xl: ms(2) !default;
$fs-xxl: ms(3) !default;
$fs-xxxl: ms(4) !default;
$fs-xxxxl: ms(7) !default;
$fs-xxxxxl: ms(12) !default;


@function rem2px($value) {
  @return $value / ($value * 0 + 1) * 16px;
}

@if (inuit-config(debug) == true) {

  @debug "$fs-xxs   ms(-3)" ms(-3)  rem2px(ms(-3));
  @debug "$fs-xs    ms(-2)" ms(-2)  rem2px(ms(-2));
  @debug "$fs-s     ms(-1)" ms(-1)  rem2px(ms(-1));
  @debug "$fs-base  ms()  " ms()    rem2px(ms());
  @debug "$fs-l     ms(1) " ms(1)   rem2px(ms(1));
  @debug "$fs-xl    ms(2) " ms(2)   rem2px(ms(2));
  @debug "$fs-xxl   ms(3) " ms(3)   rem2px(ms(3));
  @debug "$fs-xxxl  ms(4) " ms(5)   rem2px(ms(4));
  @debug "$fs-xxxxl ms(5) " ms(6)   rem2px(ms(6));

}

// Fluid Type settings
$fluid-min-vw-breakpoint: hand;
$fluid-max-vw-breakpoint: desk;
$fluid-min-value: 1em;
$fluid-max-value: 1.125em;


// Line height
//$lh-default: 1.3333 !default;
$lh-default: 1.5 !default;
$lh-base: 1rem * $lh-default !default;

// Optimized line length
$optimal-length: 40em !default;

$text-width: 40em !default;
$text-width-small: 12.5em !default;
$text-width-medium: 33em !default;


// Heading font sizes
$fs-h1: $fs-xxxxxl !default;
$fs-h2: $fs-xxl !default;
$fs-h3: $fs-xl !default;
$fs-h4: $fs-l !default;
$fs-h5: $fs-l !default;
$fs-h6: $fs-base !default;


// Inuit
$inuit-global-font-size: $fs-base;
$inuit-global-line-height: $lh-base;

$inuit-font-size-h1: $fs-h1;
$inuit-font-size-h2: $fs-h2;
$inuit-font-size-h3: $fs-h3;
$inuit-font-size-h4: $fs-h4;
$inuit-font-size-h5: $fs-h5;
$inuit-font-size-h6: $fs-h6;


