///* ========================================================================
//   #FONT-FACE GENERATOR
//   ======================================================================== */

/// Generate font-face at-rules
///
/// @example scss - Define fonts and font styles in a map
///
///     $siteFonts: (
///       base: (                             // 1st font (SASS alias for this font)
///         name: "Font Name",                // Font Name          (required)
///         title: "Font Title",              // Font title         (optinal, used for CSS comment)
///         path: "../fonts/",                // Path to font files (optional)
///         fallback: "Times, serif",         // Fallback fonts     (optional, but recommended)
///         formats: eot woff woff2 ttf svg,  // Available font formats (eot, woff, woff2, ttf, svg) (required)
///         font-loader: ff0,                 // Font should be triggered by a font loader (ff0 = Font Face Observer, wfl = Web Font Loader) (optional)
///         styles: (
///           "normal": (                     // Font style name    (requried, used for CSS comment)
///             file: "font/font-regular",    // Font file          (required)
///             font-style: normal,           // Font-style         (optional, but recommended)
///             font-weight: 400,             // Font-weight        (optional, but recommended)
///             font-variant: small-caps,     // Font-variant       (optional)
///             font-stretch: condensed,      // Font-stretch       (optional)
///             unicode-range: U+0025-00FF,   // Unicode-range      (optional)
///           ),
///           "bold": (
///             file: "font-700/font-700",
///             font-style: normal,
///             font-weight: 700,
///           ),
///         )
///       ),
///       display: (                          // 2nd font
///         title: "Font Title 2",
///         name: "Font Name 2",
///         path: "../fonts/",
///         fallback: "Arial, sans-serif",
///         formats: eot woff woff2 ttf svg,
///         styles: (
///           "bold": (
///             file: "font-2/font-2-700",
///             font-style: normal,
///             font-weight: 700,
///           ),
///         )
///       )
///     );
///
/// @example scss - Output font-face at-rules for fonts defined in a map `$siteFonts` and suppress CSS comments
///
///     @include font-faces($siteFonts, false);
///
/// @author Dennis Frank
/// @access public
/// @param {map} $fonts-map [$fonts] - Sass map with font defintions
/// @param {booleans} $comments [true] - Should a comment be added to each `@font-face at-rule?
@mixin font-faces($fonts-map: $fonts, $comments: true) {

  // Loop through each font’s attributes
  @each $font, $settings in $fonts-map {

    // Check if the 'styles' attribute exists
    @if map-has-key($settings, styles) {

      @each $style, $attributes in map-get($settings, styles) {

        // Font file path without extension
        $fontFile:      unquote(#{map-get($settings, path)}#{map-get($attributes, file)});

        // Set attributes variables
        $name:          map-get($settings, name);
        $title:         map-get($settings, title);
        $font-weight:   map-get($attributes, font-weight);
        $font-style:    map-get($attributes, font-style);
        $font-stretch:  map-get($attributes, font-stretch);
        $font-variant:  map-get($attributes, font-variant);
        $unicode-range: map-get($attributes, unicode-range);

        // Output title and style as CSS comment
        @if $comments {
          @if $title {
            /* #{$title $style} */
          } @else {
            /* #{$style} */
          }
        }

        // Beginn @font-face at-rule
        @font-face {
          font-family:   $name;
          font-weight:   $font-weight;
          font-style:    $font-style;
          font-stretch:  $font-stretch;
          font-variant:  $font-variant;
          unicode-range: $unicode-range;

          // Font formats (eot, woff, …)
          $formats: map-get($settings, formats);

          @include fontSrc($formats, $fontFile);
        }
      }
    } @else {

      @warn "Oops. Please add styles attributes for this font!";

    }
  }
}





// Use a mixin to generate src attributes
@mixin fontSrc($formats: null, $fontFile: null) {

  $src-url: null;

  // Single .eot src
  @if index($formats, eot) {
    src: url("#{$fontFile}.eot");
  }

  // .eot
  @if index($formats, eot) {
    $src-url: append($src-url, url("#{$fontFile}.eot?#iefix") format("embedded-opentype"));
  }

  // .woff2
  @if index($formats, woff2) {
    @if index($formats, eot) {
      // prepend comma ','
      $src-url: append($src-url, unquote(','));
    }
    $src-url: append($src-url, url("#{$fontFile}.woff2") format("woff2"));
  }

  // .woff
  @if index($formats, woff) {
    @if index($formats, eot) or index($formats, woff2) {
      // prepend comma ','
      $src-url: append($src-url, unquote(','));
    }
    $src-url: append($src-url, url("#{$fontFile}.woff") format("woff"));
  }

  // .ttf
  @if index($formats, ttf) or index($formats, truetype) {
    @if index($formats, eot) or index($formats, woff2) or index($formats, woff) {
      // prepend comma ','
      $src-url: append($src-url, unquote(','));
    }
    $src-url: append($src-url, url("#{$fontFile}.ttf") format("truetype"));
  }

  // .svg
  @if index($formats, svg) {
    @if index($formats, eot) or index($formats, woff2) or index($formats, woff) or index($formats, ttf) or index($formats, truetype) {
      // prepend comma ','
      $src-url: append($src-url, unquote(','));
    }
    $src-url: append($src-url, url("#{$fontFile}.svg##{map-get($settings, name)}") format("svg"));
  }

  src: $src-url;
}
