/* ==========================================================================
   #PAGE
   ========================================================================== */

*, *:after, *.before {
  font-family: inherit;
  line-height: inherit;
  color: inherit;
}

:root {
  @include fluid-value(font-size, $fluid-min-value, $fluid-max-value);
  line-height: $lh-default;
  font-family: $ff-base;
  font-weight: $fw-normal;
  min-height: 100%;
}

body {
  overflow-x: hidden;
  background-color: $color-page;
  background-color: $color-background-dark;
  color: $color-text-invert;
}
