/* ==========================================================================
   #PAGE-FOOT
   ========================================================================== */


.c-page-foot {
  padding-top: $unit;
  padding-bottom: $unit-large;
  text-align: center;
  background-color: $color-background-medium;
  border-top: 1px solid $color-brand;

  @include mq($from: lap) {
    padding-top: $unit-large;
  }
}
