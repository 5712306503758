/* ==========================================================================
   #WIDTHS-AUTO
   ========================================================================== */

$inuit-width-auto: true !default;
$inuit-responsive-width-auto: false !default;

$inuit-responsive-width-auto-until-include: default !default;
$inuit-responsive-width-auto-until-exclude: default !default;
$inuit-responsive-width-auto-from-include: default !default;
$inuit-responsive-width-auto-from-exclude: default !default;


@if $inuit-width-auto {

  /* Widths auto helper class */
  .u-width-auto {
    width: auto !important;
  }

}



@if $inuit-responsive-width-auto {

  /* Responsive width auto helper classes */
  @include responsify(
    $until-include: $inuit-responsive-centering-until-include,
    $until-exclude: $inuit-responsive-centering-until-exclude,
    $from-include: $inuit-responsive-centering-from-include,
    $from-exclude: $inuit-responsive-centering-from-exclude
    ) {

    .u-width-auto#{$breakpoint} {
      width: auto !important;
    }
  }

}
