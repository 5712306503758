/* ==========================================================================
   #RATIO
   ========================================================================== */

// A list of aspect ratios that get generated as modifier classes.
// You should predefine it with only the ratios and names your project needs.
//
// The map keys are the strings used in the generated class names, and they can
// follow any convention, as long as they are properly escaped strings. i.e.:
//
//   $inuit-ratios: (
//     "2\\:1"         : (2:1),
//     "4-by-3"        : (4:3),
//     "full-hd"       : (16:9),
//     "card-image"    : (2:3),
//     "golden-ratio"  : (1.618:1) -> non-integers are okay
//   ) !default;

$inuit-ratios: (
  "2\\:1"   : (2:1),
  "4\\:3"   : (4:3),
  "16\\:9"  : (16:9)
) !default;



/* Ratio variants.
   ========================================================================== */

/**
 * Generate a series of ratio classes to be used like so:
 *
 *   <div class="o-ratio  u-ratio--golden-ratio">
 *
 */

@each $ratio-name, $ratio-value in $inuit-ratios {

  @each $antecedent, $consequent in $ratio-value {

    @if (type-of($antecedent) != number) {
      @error "`#{$antecedent}` needs to be a number.";
    }

    @if (type-of($consequent) != number) {
      @error "`#{$consequent}` needs to be a number.";
    }

    .o-ratio--#{$ratio-name}:before {
      padding-bottom: ($consequent/$antecedent) * 100%;
    }

  }

}
