/* ==========================================================================
   #BOX-SIZING
   ========================================================================== */

/**
 * Box sizing without inheritance
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}
