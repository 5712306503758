/* ==========================================================================
   #IMAGE-EFFECT
   ========================================================================== */

.u-image-effect {
  background: rgba($color-brand, .2);
  box-shadow: 0 0 0 1px rgba($color-brand, .5);

  > img {
    mix-blend-mode: hard-light;
    filter: grayscale(1);
  }
}
