/* ==========================================================================
   #DISPLAY
   ========================================================================== */

$inuit-display: true !default;
$inuit-responsive-display-block: false !default;
$inuit-responsive-display-block-ie9-table-hack: false !default;

$inuit-responsive-display-none: false !default;
$inuit-responsive-display-inline: false !default;
$inuit-responsive-display-inline-block: false !default;
$inuit-responsive-display-flex: false !default;
$inuit-responsive-display-table: false !default;
$inuit-responsive-display-table-cell: false !default;
$inuit-responsive-display-table-caption: false !default;


@if $inuit-display == true {

  /**
   * Display utilities
   */
  .u-block { display: block !important; }
  .u-none { display: none !important; }
  .u-inline { display: inline-block !important; }
  .u-inline-block { display: inline-block !important; }
  .u-flex { display: flex !important; }
  .u-table { display: table !important; }
  .u-table-cell { display: table-cell !important; }
  .u-table-caption { display: table-caption !important; }

  @if $inuit-responsive-display-block-ie9-table-hack == true {
    /* IE9 Hack for display:block in tables */
    table, tr, td, th {
      &.u-block {
        width: 100%\9 !important;
        float: left\9 !important;
      }
    }
  }

}



@include responsify() {

  @if ($inuit-responsive-display-block == true) {
    /**
     * Responsive display block
     */
    .u-block#{$breakpoint} {
      display: block !important;
    }

    @if $inuit-responsive-display-block-ie9-table-hack == true {
      table.u-block#{$breakpoint},
      thead.u-block#{$breakpoint},
      tfoot.u-block#{$breakpoint},
      tbody.u-block#{$breakpoint},
      tr.u-block#{$breakpoint},
      th.u-block#{$breakpoint},
      td.u-block#{$breakpoint} {
        width: 100%\9 !important;
        float: left\9 !important;
      }
    }
  }


  @if ($inuit-responsive-display-flex == true) {
    /**
     * Responsive display flex
     */
    .u-flex#{$breakpoint} {
      display: flex !important;
    }
  }


  @if ($inuit-responsive-display-none == true) {
    /**
     * Responsive display none
     */
    .u-none#{$breakpoint} {
      display: none !important;
    }
  }


  @if ($inuit-responsive-display-inline == true) {
    /**
     * Responsive display inline
     */
    .u-inline#{$breakpoint} {
      display: inline !important;
    }
  }


  @if ($inuit-responsive-display-inline-block == true) {
    /**
     * Responsive display inline-block
     */
    .u-inline-block#{$breakpoint} {
      display: inline-block !important;
    }
  }


  @if ($inuit-responsive-display-table == true) {
    /**
     * Responsive display table
     */
    .u-table#{$breakpoint} {
      display: table !important;
    }
  }


  @if ($inuit-responsive-display-table-cell == true) {
    /**
     * Responsive display table-cell
     */
    .u-table-cell#{$breakpoint} {
      display: table-cell !important;
    }
  }


  @if ($inuit-responsive-display-table-caption == true) {
    /**
     * Responsive display table-caption
     */
    .u-table-caption#{$breakpoint} {
      display: table-caption !important;
    }
  }

}
