/* ==========================================================================
   #PAGE-BRANDING
   ========================================================================== */


.c-page-branding {
  position: absolute;
  z-index: 1;
  padding: $unit;
  font-size: $fs-s;
  font-weight: $fw-bold;
  border-bottom-width: $unit-small;
  background-color: $color-background-white;
  color: $color-text;


  @include mq($from: lap) {
    display: none;
  }

}
