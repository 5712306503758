/* ==========================================================================
   #TEXT
   ========================================================================== */

$inuit-responsive-text: false !default;

$inuit-responsive-text-until-include: default !default
$inuit-responsive-text-until-exclude: default !default
$inuit-responsive-text-from-include: default !default
$inuit-responsive-text-from-exclude: default !default


.u-text {
  font-weight: $fw-normal !important;
  line-height: inital !important;
  color: $color-text !important;
  text-decoration: none !important;
}

.u-text-xxs   { font-size: $fs-xxs !important; }
.u-text-xs    { font-size: $fs-xs !important; }
.u-text-s     { font-size: $fs-s !important; }
.u-text-base  { font-size: $fs-base !important; }
.u-text-l     { font-size: $fs-l !important; }
.u-text-xl    { font-size: $fs-xl !important; }
.u-text-xxl   { font-size: $fs-xxl !important; }
.u-text-xxxl  { font-size: $fs-xxxl !important; }
.u-text-xxxxl { font-size: $fs-xxxxl !important; }

@if $inuit-responsive-text {

  /* Responsive text helper classes */
  @include responsify(
    $until-include: $inuit-responsive-text-until-include,
    $until-exclude: $inuit-responsive-text-until-exclude,
    $from-include: $inuit-responsive-text-from-include,
    $from-exclude: $inuit-responsive-text-from-exclude
    ) {

    .u-text {
      font-weight: $fw-normal !important;
      line-height: inital !important;
      color: $color-text !important;
      text-decoration: none !important;
    }

    .u-text-xxs#{$breakpoint}   { font-size: $fs-xxs !important; }
    .u-text-xs#{$breakpoint}    { font-size: $fs-xs !important; }
    .u-text-s#{$breakpoint}     { font-size: $fs-s !important; }
    .u-text-base#{$breakpoint}  { font-size: $fs-base !important; }
    .u-text-l#{$breakpoint}     { font-size: $fs-l !important; }
    .u-text-xl#{$breakpoint}    { font-size: $fs-xl !important; }
    .u-text-xxl#{$breakpoint}   { font-size: $fs-xxl !important; }
    .u-text-xxxl#{$breakpoint}  { font-size: $fs-xxxl !important; }
    .u-text-xxxxl#{$breakpoint} { font-size: $fs-xxxxl !important; }
  }
}
