/* ==========================================================================
   #HIDE-EXPANDED
   ========================================================================== */

// .js :not([aria-expanded]) > .u-hide-expanded,
[aria-expanded='true'] .u-hide-expanded,
[aria-expanded='true'].u-hide-expanded {
  display: none
}

// .js :not([aria-expanded]) > .u-hide-not-expanded,
[aria-expanded='false'] .u-hide-not-expanded,
[aria-expanded='false'].u-hide-not-expanded {
  display: none
}
