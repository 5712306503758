/* ==========================================================================
   #TEXT-WIDTH
   ========================================================================== */

$text-width: 40em !default;
$text-width-small: 15em !default;
$text-width-medium: 30em !default;


.u-text-width {
  max-width: $text-width;
}

.u-text-width-small {
  max-width: $text-width-small;
}

.u-text-width-medium {
  max-width: $text-width-medium;
}
