/*------------------------------------*\
    #LAYOUT ADDITIONS
\*------------------------------------*/

/**
 * Stretch all grid items of each row to have an equal-height.
 * Please be aware that this modifier class doesn't take any effect in IE9 and
 * below and other older browsers due to the lack of `display: flex` support.
 */

.o-layout--stretch {

  > .o-layout__item {

    > * {
      width: 100%;
    }
  }
}


